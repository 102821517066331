import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";

import {Button} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Button/Button";

export default function StyleguideButtons() {
  const stylguideTestIcon = 'search-bold';

  return (
    <div className={'styleguide-components'}>
      <div className={'component-header'}>
        <Headings text={'Buttons'} type={'h2'} />
      </div>

      <div className="bg-mb-skim-milk">
        <div className={'component-header'}>
          <Headings text={'Light/White'} type={'h3'} />
        </div>

        <div className={'flex flex-col lg:flex-row justify-between mb-4'}>
          <div className={'col-span-2'}>
            <h5>Primary</h5>
          </div>

          <div className={'flex flex-col lg:flex-row gap-y-2 lg:space-x-2 col-span-8'}>
            <Button label={'Primary'} />

            <Button
              icon={`${stylguideTestIcon}`}
              label={'Primary'}
            />

            <Button
              icon={`${stylguideTestIcon}`}
              label={'Primary'}
              reverse
            />
            <Button label={'Primary'} disabled={true}/>

            <Button icon={`${stylguideTestIcon}`} />
          </div>
        </div>

        <div className={'flex flex-col lg:flex-row justify-between mb-4'}>
          <div className={'col-span-2'}>
            <h5>Secondary</h5>
          </div>

          <div className={'flex flex-col lg:flex-row gap-y-2 lg:space-x-2 col-span-8'}>
            <Button
              style="secondary"
              label={'Secondary'}
            />

            <Button
              style="secondary"
              icon={`${stylguideTestIcon}`}
              label={'Secondary'}
            />

            <Button
              style="secondary"
              icon={`${stylguideTestIcon}`}
              label={'Secondary'}
              reverse
            />

            <Button
                style="secondary"
                label={'Secondary'}
                disabled={true}
            />

            <Button
              style="secondary"
              icon={`${stylguideTestIcon}`}
            />
          </div>
        </div>

        <div className={'flex flex-col lg:flex-row justify-between mb-4'}>
          <div className={'col-span-2'}>
            <h5>Tertiary</h5>
          </div>

          <div className={'flex flex-col lg:flex-row gap-y-2 lg:space-x-2 col-span-8'}>
            <Button
              style="tertiary"
              label={'Tertiary'}
            />

            <Button
              style="tertiary"
              icon={`${stylguideTestIcon}`}
              label={'Tertiary'}
            />

            <Button
              style="tertiary"
              icon={`${stylguideTestIcon}`}
              label={'Tertiary'}
              reverse
            />

            <Button
                style="tertiary"
                label={'Tertiary'}
                disabled={true}
            />

            <Button
              style="tertiary"
              icon={`${stylguideTestIcon}`}
            />
          </div>
        </div>

        <div className={'flex flex-col lg:flex-row justify-between mb-4'}>
          <div className={'col-span-2'}>
            <h5>Text Button</h5>
          </div>

          <div className={'flex flex-col lg:flex-row gap-y-2 lg:space-x-2 col-span-8'}>
            <Button
              style="text"
              label={'Button'}
            />

            <Button
              style="text"
              icon={`${stylguideTestIcon}`}
              label={'Button'}
            />

            <Button
              style="text"
              icon={`${stylguideTestIcon}`}
              label={'Button'}
              reverse
            />

            <Button
              style="text"
              icon={`${stylguideTestIcon}`}
            />
          </div>
        </div>
      </div>

      <div className="p-4"></div>

      <div className="bg-[#25272A] p-[16px] text-white">
        <div className={'component-header'}>
          <Headings text={'Dark'} type={'h3'} />
        </div>

        <div className={'flex flex-col lg:flex-row justify-between mb-4'}>
          <div className={'col-span-2'}>
            <h5>Primary</h5>
          </div>

          <div className={'flex flex-col lg:flex-row gap-y-2 lg:space-x-2 col-span-8'}>
            <Button
              style="dark-primary"
              label={'Primary'}
            />

            <Button
              style="dark-primary"
              icon={`${stylguideTestIcon}`}
              label={'Primary'}
            />

            <Button
              style="dark-primary"
              icon={`${stylguideTestIcon}`}
              label={'Primary'}
              reverse
            />

            <Button
                style="dark-primary"
                label={'Primary'}
                disabled={true}
            />

            <Button
              style="dark-primary"
              icon={`${stylguideTestIcon}`}
            />
          </div>
        </div>

        <div className={'flex flex-col lg:flex-row justify-between mb-4'}>
          <div className={'col-span-2'}>
            <h5>Secondary</h5>
          </div>

          <div className={'flex flex-col lg:flex-row gap-y-2 lg:space-x-2 col-span-8'}>
            <Button
              style="dark-secondary"
              label={'Secondary'}
            />

            <Button
              style="dark-secondary"
              icon={`${stylguideTestIcon}`}
              label={'Secondary'}
            />

            <Button
              style="dark-secondary"
              icon={`${stylguideTestIcon}`}
              label={'Secondary'}
              reverse
            />

            <Button
                style="dark-secondary"
                label={'Secondary'}
                disabled={true}
            />

            <Button
              style="dark-secondary"
              icon={`${stylguideTestIcon}`}
            />
          </div>
        </div>

        <div className={'flex flex-col lg:flex-row justify-between mb-4'}>
          <div className={'col-span-2'}>
            <h5>Tertiary</h5>
          </div>

          <div className={'flex flex-col lg:flex-row gap-y-2 lg:space-x-2 col-span-8'}>
            <Button
              style="dark-tertiary"
              label={'Tertiary'}
            />

            <Button
              style="dark-tertiary"
              icon={`${stylguideTestIcon}`}
              label={'Tertiary'}
            />

            <Button
              style="dark-tertiary"
              icon={`${stylguideTestIcon}`}
              label={'Tertiary'}
              reverse
            />

            <Button
                style="dark-tertiary"
                label={'Tertiary'}
                disabled={true}
            />

            <Button
              style="dark-tertiary"
              icon={`${stylguideTestIcon}`}
            />
          </div>
        </div>

        <div className={'flex flex-col lg:flex-row justify-between mb-4'}>
          <div className={'col-span-2'}>
            <h5>Text Button</h5>
          </div>

          <div className={'flex flex-col lg:flex-row gap-y-2 lg:space-x-2 col-span-8'}>
            <Button
              style="dark-text"
              label={'Button'}
            />

            <Button
              style="dark-text"
              icon={`${stylguideTestIcon}`}
              label={'Button'}
            />

            <Button
              style="dark-text"
              icon={`${stylguideTestIcon}`}
              label={'Button'}
              reverse
            />

            <Button
              style="dark-text"
              icon={`${stylguideTestIcon}`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}