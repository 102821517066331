import React, { lazy } from 'react';
import { lazyComponents } from '@src/utils/LazyComponent';

const AccountLanding = lazy(() => import('@project-stories/ecommerce/Account/AccountLanding/AccountLanding'));
AccountLandingComponent.displayName = 'theme__account-landing';
export function AccountLandingComponent({ ...props }) {
  const { section, signUpFields, section_image, section_image_mobile, color_schemas } = props.settings;

  return (
    <AccountLanding
      section={section}
      signUpFields={signUpFields}
      image={section_image}
      mobileImage={section_image_mobile}
      colorSchemas={color_schemas}
    />
  );
}

AccountResetComponent.displayName = 'theme__account-reset';
export function AccountResetComponent({ ...props }) {
  const { section, section_image, section_image_mobile, color_schemas } = props.settings;

  return (
    <AccountReset
      section={section}
      image={section_image}
      mobileImage={section_image_mobile}
      headerColorSchema={color_schemas.header_color_schema}
    />
  );
}

const AccountActivate = lazy(() =>
    import('@project-stories/ecommerce/Account/AccountActivate/AccountActivate')
);
AccountActivateComponent.displayName = 'theme__account-activate';
export function AccountActivateComponent({ ...props }) {
  const { section, section_image, section_image_mobile, color_schemas } = props.settings;

  return (
    <AccountActivate
      section={section}
      image={section_image}
      mobileImage={section_image_mobile}
      headerColorSchema={color_schemas.header_color_schema}
    />
  );
}

const AccountProfileForm = lazy(() =>
  import('@project-stories/ecommerce/Account/AccountProfileForm/AccountProfileForm')
);
AccountProfileComponent.displayName = 'theme__account-profile';
export function AccountProfileComponent({ ...props }) {
  const { section, signUpFields } = props.settings;

  return (
    <AccountProfileForm
      section={section}
      signUpFields={signUpFields}
    />
  );
}

const AccountOrdersLanding = lazy(() =>
  import('@project-stories/ecommerce/Account/AccountOrders/AccountOrdersLanding')
);
AccountOrdersLandingComponent.displayName = 'theme__account-orders';
export function AccountOrdersLandingComponent({ ...props }) {
  const { orders, locales } = props.settings;

  return (
    <AccountOrdersLanding
      orders={orders}
      locales={locales}
    />
  );
}

const AccountAddressesLanding = lazy(() =>
  import('@project-stories/ecommerce/Account/AccountAddresses/AccountAddressesLanding')
);
AccountAddressesLandingComponent.displayName = 'theme__account-addresses';
export function AccountAddressesLandingComponent({ ...props }) {
  const { defaultCountry, locales } = props.settings;

  return (
    <AccountAddressesLanding
      defaultCountry={defaultCountry}
      locales={locales}
    />
  );
}

const AccountReturns = lazy(() => import('@project-stories/ecommerce/Account/AccountReturns/AccountReturns'));
AccountReturnsComponent.displayName = 'theme__account-returns';
export function AccountReturnsComponent({ ...props }) {
  const { promoLabelConfig, locales } = props.settings;

  return (
    <AccountReturns
      promoLabelConfig={promoLabelConfig}
      locales={locales}
    />
  );
}

AccountWishlistComponent.displayName = 'theme__account-wishlist';
export function AccountWishlistComponent({ ...props }) {
  const AccountWishlist = lazyComponents['AccountWishlist'];

  const {
    settings,
    settings: { locales }
  } = props;

  return (
    <AccountWishlist
      settings={settings}
      locales={locales}
    />
  );
}

const AccountChangePassword = lazy(() =>
  import('@project-stories/ecommerce/Account/AccountChangePasswordForm/AccountChangePassword')
);
AccountChangePasswordComponent.displayName = 'theme__account-change-password';
export function AccountChangePasswordComponent({ ...props }) {
  const { section } = props.settings;

  return <AccountChangePassword section={section} />;
}

const AccountSidebar = lazy(() => import('@project-stories/ecommerce/Account/AccountSidebar/AccountSidebar'));
AccountSidebarComponent.displayName = 'theme__account-sidebar';
export function AccountSidebarComponent({ ...props }) {
  const { menu, colorSchema } = props.settings;

  return (
    <AccountSidebar
      menu={menu}
      colorSchema={colorSchema}
    />
  );
}
