import { useEffect, useState } from "react";
import shopify from "../js/shopify";
import { lazyComponents } from "@src/utils/LazyComponent";

StyleWithComponent.displayName = 'theme__lm-stylewith';
export function StyleWithComponent({ ...props }) {
    const StyleWith = lazyComponents['StyleWith'];

    const [ products, setProducts] = useState([]);
    const [ styleProducts, setStyleProducts] = useState([]);
    const [ loadedProducts, setLoadedProducts] = useState(false);
    const { product,product_id } = props.settings;
    const { product_ids } = props.settings;

    useEffect(() => {
        if (product_ids && product_ids.length > 0) {
            async function fetchProducts(productIds) {
                const validProductIds = productIds.filter(id => id); // Ensure no null or empty IDs
                if (validProductIds.length > 0) {
                    const products = await shopify.getShopifyProductList(validProductIds);
                    setLoadedProducts(true);
                    setStyleProducts(products);
                }
            }

            fetchProducts(product_ids);
        }

        async function fetchProductRecommendations(productId) {
            // console.log(`Fetching recommended products for: ${productId}`);
            const recommendedProducts = await shopify.getRecommendedProducts(productId);
            setLoadedProducts(true);
            setProducts(recommendedProducts);
        }

        if (product?.id || product_id) {
            fetchProductRecommendations(product.id || product_id);
        }
    }, [product_ids]);

    if (!loadedProducts){
        return null;
    }

    return (
        <StyleWith
            products={products}
            settings={props.settings}
            style_products={styleProducts}
        />
    );
}

CurrencySelectorComponent.displayName = 'theme__currency-selector';
export function CurrencySelectorComponent({ ...props }) {
    const CurrencySelector = lazyComponents['CurrencySelector'];
    return (
        <CurrencySelector settings={props} />
    );
}

OutOfStockComponent.displayName = 'theme__client_outofstock';
export function OutOfStockComponent({ ...props }) {
    const BackInStock = lazyComponents['BackInStock'];
    return (
        <BackInStock settings={props} />
    );
}

FindInStoreComponent.displayName = 'theme__client_findinstore';
export function FindInStoreComponent({ ...props }) {
    const FindInStore = lazyComponents['FindInStore'];
    return (
        <FindInStore settings={props} />
    );
}
